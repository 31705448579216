import { parse } from 'twemoji-parser'

export function parseTextWithEmojis(text: string) {
  let textWithEmojis = text
  parse(text).forEach(emoji => {
    textWithEmojis = textWithEmojis.replace(
      emoji.text,
      `<img src="${emoji.url}" />`
    )
  })

  return textWithEmojis
}

export function countTextLenghtWithEmojis(text: string) {
  const joiner = '\u{200D}'
  let count = 0
  const split = text.split(joiner)
  split.forEach(s => {
    const num = Array.from(s.split(/[\ufe00-\ufe0f]/).join('')).length
    count += num
  })

  return count / split.length
}
