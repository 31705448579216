<template>
  <FormItemWrapper
    type="input"
    :has-errors="hasErrors"
    :force-show-form="forceShowForm"
    :editable="editable"
    :no-wrap-text="noWrapText"
    :focus-background-color="focusBackgroundColor"
  >
    <template #text>
      <a-form-item
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :required="required"
      >
        <div class="form-item-text">{{ inputValue || emptyText }}</div>
      </a-form-item>
    </template>
    <template #input="{ toggleEditMode }">
      <EmojisPicker v-if="withEmojis" @emoji-selected="inputEmoji" />
      <a-form-model-item
        ref="formModelItem"
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :required="required"
        :class="{ 'current-and-max-length-visible': maxLength !== undefined }"
        :rules="customRules"
        :prop="prop"
      >
        <a-input
          :auto-focus="autoFocus"
          :value="inputValue"
          :data-cy="inputDataCy"
          v-bind="$attrs"
          v-on="{
            ...$listeners,
            input: event => (inputValue = event.target.value)
          }"
          @pressEnter="event => event.target.blur()"
          @blur="$event => onBlur($event, toggleEditMode)"
        />
        <TextLengthValidator
          :value="inputValue"
          :max-length="maxLength"
          :characters-count-width="charactersCountWidth"
        />
      </a-form-model-item>
    </template>
  </FormItemWrapper>
</template>
<script>
import EmojisPicker from '@/components/base/EmojisPicker.vue'
import FormItemWrapper from '@/components/common/forms/FormItemWrapper'
import TextLengthValidator from '@/components/common/validators/TextLengthValidator.vue'
import { rulesMessages } from '@/constants/error-messages'
import { requiredRule } from '@/constants/validation-rules'
import HInputMixin from '@/mixins/HInputMixin'

export default {
  components: { TextLengthValidator, FormItemWrapper, EmojisPicker },
  extends: HInputMixin,
  props: {
    withEmojis: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    minLength: {
      type: Number,
      required: false
    },
    maxLength: {
      type: Number,
      required: false
    },
    charactersCountWidth: {
      type: String,
      default: '60px'
    },
    inputDataCy: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      selectionEnd: -1
    }
  },
  computed: {
    inputValue: {
      set(value) {
        this.$emit('input', value)
        this.prop && this.$refs.formModelItem?.onFieldChange()
      },
      get() {
        return this.value
      }
    },
    customRules() {
      const customRules = []

      if (this.required) {
        customRules.push(requiredRule)
      }

      if (this.minLength) {
        customRules.push({
          min: this.minLength,
          trigger: ['change', 'blur'],
          message: rulesMessages.str.min(this.minLength)
        })
      }

      if (this.maxLength) {
        customRules.push({
          max: this.maxLength,
          trigger: ['change', 'blur'],
          message: rulesMessages.str.max(this.maxLength)
        })
      }

      return [...this.userRules, ...customRules]
    },
    currentLength() {
      const isString = typeof this.inputValue === 'string'
      return isString ? this.inputValue.length : 0
    }
  },
  methods: {
    onBlur($event, toggleEditMode) {
      this.selectionEnd = $event.target.selectionEnd
      const classList = $event?.relatedTarget?.classList
      const emoji = classList && classList.contains('emoji-invoker')
      if (emoji) {
        // emoji picker was clicked, so we don't close input
        return
      }
      toggleEditMode($event)
      this.prop && this.$refs.formModelItem?.onFieldBlur()
    },
    inputEmoji(emoji) {
      const currentValue = this.inputValue || ''
      const selectionEnd =
        this.selectionEnd >= 0 ? this.selectionEnd : currentValue.length
      this.inputValue =
        currentValue.substring(0, selectionEnd) +
        emoji +
        currentValue.substring(selectionEnd)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .current-and-max-length-visible {
    .ant-form-explain {
      width: calc(100% - v-bind(charactersCountWidth));
    }
  }
}
</style>
