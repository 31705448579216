<template>
  <EmojiPicker :search="search" @emoji="selectEmoji">
    <div
      slot="emoji-invoker"
      slot-scope="{ events: { click: clickEvent } }"
      class="invoker-conteiner"
      @click.stop="clickEvent"
    >
      <a-icon class="emoji-invoker" type="smile" />
    </div>
    <div
      slot="emoji-picker"
      slot-scope="{ emojis, insert }"
      class="picker-container"
    >
      <div class="emoji-picker">
        <a-input-search v-model="search" class="search" type="text" />
        <div class="container">
          <div
            v-for="(emojiGroup, category) in emojis"
            :key="category"
            class="category-container"
          >
            <h5>{{ category }}</h5>
            <div
              v-for="(emoji, emojiName) in emojiGroup"
              :key="emojiName"
              class="emoji-container"
              :title="emojiName"
              @click="insert(emoji)"
              v-html="toTwemoji(emoji)"
            />
          </div>
        </div>
      </div>
    </div>
  </EmojiPicker>
</template>
<script setup>
import twemoji from 'twemoji'
import { ref } from 'vue'
import EmojiPicker from 'vue-emoji-picker'

const emit = defineEmits(['emoji-selected'])

const search = ref('')

const selectEmoji = emoji => {
  emit('emoji-selected', emoji)
}

const toTwemoji = emoji => twemoji.parse(emoji)
</script>
<style scoped lang="scss">
.invoker-conteiner {
  position: relative;
}

.emoji-invoker {
  color: $neutral-4;
  position: absolute;
  top: 30px;
  z-index: 1;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}

.emoji-invoker:hover {
  transform: scale(1.1);
  color: $neutral-8;
}

.emoji-container {
  width: 40px;
  height: 40px;
  display: inline-block;
  transform: scale(0.4);
  cursor: pointer;
}

.picker-container {
  position: relative;
}

.emoji-picker {
  position: absolute;
  top: 55px;
  right: 0;
  z-index: 2;
  border: 1px solid $grey-border;
  box-sizing: border-box;
  border-radius: 16px;
  background: $white;
  box-shadow: 1px 1px 8px $neutral-3;
}

.search {
  padding: 4px;

  ::v-deep .ant-input {
    border: 1px solid $grey-border !important;
    box-shadow: none !important;
  }
}

.emoji-picker h5 {
  margin-bottom: 0;
  margin-left: 10px;
}

.container {
  overflow-y: scroll;
  width: 390px;
  height: 350px;
}
</style>
