import { appendHigherLevel } from '@/functions/idp'

const plan = {
  gradeLevelId: 13,
  aimName: 'IB',
  minLessons: 30,
  maxLessons: 36,
  choices: {
    ELA: {
      choice_type: 'default',
      basket: 'English I',
      codeStartsWith: 'ELA',
      types: ['IB2'],
      approvalRequired: false,
      courseRequestAvailable: true,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false,
      customSwapFilter(course) {
        return course.departments.includes('MLA')
      }
    },
    MLA: {
      choice_type: 'default',
      basket: 'Modern Language',
      types: ['IB2'],
      department: 'MLA',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    MAT: {
      basket: 'Mathematics',
      types: ['IB2'],
      department: 'MAT',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    SCI: {
      choice_type: 'default',
      basket: 'Science',
      types: ['IB2'],
      department: 'SCI',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    SSD: {
      choice_type: 'default',
      basket: 'Social Science',
      types: ['IB2'],
      department: 'SSD',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    Free: {
      choice_type: 'free_choice',
      basket: 'Free Choice',
      types: ['IB2'],
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    'Add-on': {
      choice_type: 'addon',
      basket: 'Add-on',
      numberOfLessons: 2,
      codeStartsWith: 'IBO500',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    'Add-on 2': {
      choice_type: 'addon',
      basket: 'Add-on 2',
      numberOfLessons: 2,
      codeStartsWith: 'IBO510',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    'Add-on 3': {
      choice_type: 'addon',
      basket: 'Add-on 3',
      numberOfLessons: 2,
      codeStartsWith: 'IBO520',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    }
  },
  allowPrivateFormset: true,
  privateFormsetChoice: {
    basket: 'Optional',
    optional: true,
    forcePrivate: true
  },
  rules: []
}

export default appendHigherLevel(plan)
