import {
  CourseFamily,
  CourseGroup,
  CourseIntelligence,
  CourseLevel,
  CourseSubject,
  CourseType,
  Department
} from '@/api/index.js'

export default {
  namespaced: true,
  state: () => ({
    subjects: [],
    levels: [],
    types: [],
    groups: [],
    intelligences: [],
    families: [],
    departments: []
  }),
  mutations: {
    setSubjects(state, payload) {
      state.subjects = payload
    },
    setLevels(state, payload) {
      state.levels = payload
    },
    setTypes(state, payload) {
      state.types = payload
    },
    setGroups(state, payload) {
      state.groups = payload
    },
    setIntelligences(state, payload) {
      state.intelligences = payload
    },
    setFamilies(state, payload) {
      state.families = payload
    },
    setDepartments(state, payload) {
      state.departments = payload
    }
  },
  actions: {
    fetchSubjects({ commit }) {
      CourseSubject.all().then(response => {
        const subjects = response.data.results.map(item => ({
          label: item.name || item.id,
          value: item.id
        }))
        commit('setSubjects', subjects)
      })
    },
    fetchLevels({ commit }) {
      CourseLevel.all().then(response => {
        const levels = response.data.results.map(item => ({
          label: item.name || item.id,
          value: item.id
        }))
        commit('setLevels', levels)
      })
    },
    fetchTypes({ commit }) {
      CourseType.all().then(response => {
        const types = response.data.results.map(item => ({
          label: item.name || item.id,
          value: item.id
        }))
        commit('setTypes', types)
      })
    },
    fetchGroups({ commit }) {
      CourseGroup.all().then(response => {
        const groups = response.data.results.map(item => ({
          label: item.name || item.id,
          value: item.id
        }))
        commit('setGroups', groups)
      })
    },
    fetchIntelligences({ commit }) {
      CourseIntelligence.all().then(response => {
        const intelligences = response.data.results.map(item => ({
          label: item.name || item.id,
          value: item.id
        }))
        commit('setIntelligences', intelligences)
      })
    },
    fetchFamilies({ commit }) {
      CourseFamily.all().then(response => {
        const families = response.data.results.map(item => ({
          label: item.name || item.id,
          value: item.id
        }))
        commit('setFamilies', families)
      })
    },
    fetchDepartments({ commit }) {
      Department.all().then(response => {
        const departments = response.data.results.map(department => ({
          label: department.name,
          value: department.id
        }))
        commit('setDepartments', departments)
      })
    }
  }
}
