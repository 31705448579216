<template>
  <a-pagination
    v-bind="{ ...pagination }"
    :item-render="paginationItemRender"
    @change="onChange"
  />
</template>
<script lang="jsx">
export default {
  props: {
    pagination: {
      type: [Boolean, Object],
      required: false
    }
  },
  methods: {
    paginationItemRender(page, type, originalElement) {
      if (type === 'jump-prev' || type === 'jump-next') {
        return (
          <a class="ant-pagination-item-link">
            <div class="ant-pagination-item-container">
              <a-icon
                type={type === 'jump-next' ? 'double-right' : 'double-left'}
                class="ant-pagination-item-link-icon"
              />
              <span class="ant-pagination-item-ellipsis">...</span>
            </div>
          </a>
        )
      }
      return originalElement
    },
    onChange(page, pageSize) {
      this.$nextTick(() =>
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      )
      if (this.pagination.onChange) {
        this.pagination.onChange(page, pageSize)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .ant-pagination-item {
  font-family: 'Inter', sans-serif;
  letter-spacing: normal;

  &.ant-pagination-item-active {
    font-weight: 400;
  }
}

::v-deep .ant-pagination-jump-prev,
::v-deep .ant-pagination-jump-next {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  outline: 0;
}

.ant-pagination-item-ellipsis {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  color: var(--black-color);
  letter-spacing: normal;
}
</style>
