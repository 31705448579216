export default {
  gradeLevelId: 1,
  aimName: 'RIC',
  minLessons: 30,
  maxLessons: 36,
  choices: {
    ENG: {
      choice_type: 'default',
      basket: 'English',
      level: 'PRI',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'ENG',
      approvalRequired: false,
      courseRequestAvailable: true,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    MAT: {
      choice_type: 'default',
      basket: 'Mathematics',
      level: 'PRI',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'MAT',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    SSD: {
      choice_type: 'default',
      basket: 'Social Science',
      level: 'PRI',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'SSD',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    'HUM/SSD': {
      choice_type: 'default',
      basket: 'Social Science',
      level: 'PRI',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'SSD',
      group: 'ACD',
      family: 'HUM',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    SCI: {
      choice_type: 'default',
      basket: 'Science',
      level: 'PRI',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'SCI',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    MLA: {
      choice_type: 'default',
      basket: 'Modern Language',
      level: 'PRI',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'MLA',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    'Subject ITM': {
      choice_type: 'default',
      basket: 'Information Technology',
      level: 'PRI',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      codeStartsWith: 'IT',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    ART: {
      choice_type: 'default',
      basket: 'Art',
      level: 'PRI',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'ART',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    'ART 2': {
      choice_type: 'default',
      basket: 'Performing Art',
      level: 'PRI',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'ART',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    'ART 3': {
      choice_type: 'default',
      basket: 'Visual Art',
      level: 'PRI',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'ART',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    'Cultural Enrichment': {
      choice_type: 'default',
      basket: 'Cultural Enrichment',
      level: 'PRI',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'MLA',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    'PRI Project': {
      choice_type: 'default',
      basket: 'Project',
      level: 'PRI',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      isTalentAndEnrichment: false,
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    'Physical Education': {
      choice_type: 'default',
      basket: 'Physical Education',
      level: 'PRI',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      isTalentAndEnrichment: true,
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    }
  },
  allowPrivateFormset: true,
  privateFormsetChoice: {
    basket: 'Optional',
    optional: true,
    forcePrivate: true
  },
  rules: []
}
