import { rulesMessages } from '@/constants/error-messages'

export const requiredRule = {
  message: rulesMessages.required,
  required: true,
  trigger: ['blur', 'change']
}

export const temperatureRule = {
  message:
    'This field does not match pattern and should be between 30.0 and 49.9',
  pattern: /[3,4]\d\.\d/,
  required: true,
  trigger: ['blur', 'change']
}
