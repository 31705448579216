<script lang="jsx">
import axios from 'axios'

export default {
  data() {
    return {
      version: undefined,
      unableToFetch: false,
      updateStatusInterval: null,
      alertDisplayed: false
    }
  },
  computed: {
    baseUrl() {
      return (
        location.protocol +
        '//' +
        location.hostname +
        (location.port ? ':' + location.port : '')
      )
    },
    url() {
      return this.baseUrl + '/version.txt' + `?v=${Date.now()}`
    }
  },
  beforeDestroy() {
    if (this.updateStatusInterval) {
      clearInterval(this.updateStatusInterval)
      this.updateStatusInterval = null
    }
  },
  created() {
    this.fetchInitialVersion()
  },
  methods: {
    fetchInitialVersion() {
      axios
        .get(this.url)
        .then(res => {
          this.version = res.data
          this.setTimer()
        })
        .catch(() => {
          this.unableToFetch = true
        })
    },
    setTimer() {
      if (!this.updateStatusInterval) {
        this.updateStatusInterval = setInterval(() => {
          this.checkVersion()
        }, 60000)
      }
    },
    showAlert() {
      const key = `open${Date.now()}`
      this.alertDisplayed = key

      this.$notification.open({
        message: 'New version available',
        duration: 0,
        description:
          "There's new version available. Please save your work and refresh website, or click Refresh",
        btn: () => (
          <a-button type="primary" size="small" onClick={this.refreshBrowser}>
            Refresh
          </a-button>
        ),
        key,
        onClose: () => {
          this.alertDisplayed = null
        }
      })
    },
    refreshBrowser() {
      window.location.reload(true) // true to fetch without cache
    },
    async checkVersion() {
      try {
        const response = await axios.get(this.url)
        const newVersion = response.data
        const showAlert = this.version && newVersion !== this.version

        if (!this.alertDisplayed && showAlert) {
          this.showAlert()
        }
      } catch (error) {
        // in dev do nothing
      }
    }
  }
}
</script>

<style scoped>
.new-version-alert {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 123;
}
</style>
