export default {
  namespaced: true,
  state: () => ({
    cancellationChoices: [
      {
        value: 'no_cancellation',
        label: "Don't cancel classes"
      },
      {
        value: 'msl_hsl_enrolled',
        label:
          'Cancel all the MSL and HSL classes for the students enrolled in this exam session.'
      },
      {
        value: 'msl_hsl_enrolled_and_not_enrolled',
        label:
          'Cancel all the MSL and HSL classes for all the students from selected years (enrolled and not enrolled in this exam session).'
      },
      {
        value: 'enrolled',
        label:
          'Cancel all the classes for the students enrolled in this exam session.'
      },
      {
        value: 'enrolled_and_not_enrolled',
        label:
          'Cancel all the classes for all the students from selected years (enrolled and not enrolled in this exam session)'
      }
    ],
    examTypeChoices: [
      {
        value: 'quarter',
        label: 'Quarter exams'
      },
      {
        value: 'academic',
        label: 'Academic'
      },
      {
        value: 'external',
        label: 'External exams'
      }
    ]
  }),
  mutations: {},
  actions: {},
  getters: {}
}
