import { Api } from './__generated-api'

export const httpService = new Api({
  baseURL: import.meta.env.VITE_API_URL
})

httpService.instance.interceptors.request.use(config => {
  config.headers['Authorization'] = window.localStorage.getItem('token')
  if (window.localStorage.getItem('lang')) {
    config.headers['Accept-Language'] = window.localStorage.getItem('lang')
  }

  const safeModeDisabled = window.localStorage.getItem('safeModeDisabled')

  if (safeModeDisabled) {
    config.headers['safe-mode-disabled'] = safeModeDisabled
  }
  config.paramsSerializer = {
    indexes: null
  }
  return config
})
