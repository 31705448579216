<template>
  <a-date-picker v-model="valueMoment" v-bind="passedProps" />
</template>
<script>
import moment from 'moment'

export default {
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: [String],
      required: false
    }
  },
  computed: {
    valueMoment: {
      set(value) {
        const parsedValue = value ? value.format('YYYY-MM-DD') : value
        this.$emit('change', parsedValue)
      },
      get() {
        return this.value ? moment(this.value) : this.value
      }
    },
    passedProps() {
      return this.$attrs
    }
  }
}
</script>
