<script>
import { isArray } from 'lodash'

import { requiredRule } from '@/constants/validation-rules'

export default {
  props: {
    label: {
      type: String,
      required: false
    },
    labelColon: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Object,
      default: () => ({ 'validate-status': '', help: '' })
    },
    emptyText: {
      type: String,
      default: 'n/a'
    },
    forceShowForm: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    noWrapText: {
      type: Boolean,
      default: false
    },
    focusBackgroundColor: {
      type: String,
      default: 'white'
    },
    autoFocus: {
      type: Boolean,
      default: true
    },
    rules: {
      type: [Object, Array],
      default: () => []
    },
    prop: {
      type: String,
      required: false
    }
  },
  computed: {
    hasErrors() {
      return (
        !!this.errors.help ||
        this.$refs.formModelItem?.validateState === 'error'
      )
    },
    userRules() {
      return isArray(this.rules) ? this.rules : [this.rules]
    },
    customRules() {
      const customRules = []

      if (this.required) {
        customRules.push(requiredRule)
      }

      return [...this.userRules, ...customRules]
    }
  }
}
</script>
