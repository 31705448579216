export default {
  gradeLevelId: 6,
  aimName: 'RIC',
  minLessons: 30,
  maxLessons: 36,
  choices: {
    ELA: {
      choice_type: 'default',
      basket: 'English I',
      numberOfLessons: 7,
      level: 'MSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      // codeStartsWith: 'ELA',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    ELT: {
      choice_type: 'default',
      basket: 'English II',
      level: 'MSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      subject: 'ELT',
      approvalRequired: false,
      courseRequestAvailable: true,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    MAT: {
      choice_type: 'default',
      basket: 'Mathematics',
      level: 'MSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'MAT',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    SCI: {
      choice_type: 'default',
      basket: 'Science',
      level: 'MSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'SCI',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    MLA: {
      choice_type: 'default',
      basket: 'Modern Language',
      level: 'MSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'MLA',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    'HUM/SSD': {
      choice_type: 'default',
      basket: 'Social Science',
      level: 'MSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'SSD',
      group: 'ACD',
      family: 'HUM',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: false,
      privateAvailable: false,
      forcePrivate: false
    },
    SSD: {
      choice_type: 'default',
      basket: 'Social Science',
      level: 'MSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'SSD',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    'SSD 2': {
      choice_type: 'default',
      basket: 'Social Science II',
      level: 'MSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'SSD',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    'SSD 3': {
      choice_type: 'default',
      basket: 'Social Science III',
      level: 'MSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'SSD',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    ART: {
      choice_type: 'default',
      basket: 'Art',
      level: 'MSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'ART',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    'ART 2': {
      choice_type: 'default',
      basket: 'Performing Art',
      level: 'MSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'ART',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    },
    'ART 3': {
      choice_type: 'default',
      basket: 'Visual Art',
      level: 'MSL',
      types: ['RIC', 'GCE', 'AP1', 'AP2'],
      department: 'ART',
      approvalRequired: false,
      courseRequestAvailable: false,
      swapAvailable: true,
      privateAvailable: false,
      forcePrivate: false
    }
  },
  allowPrivateFormset: true,
  privateFormsetChoice: {
    basket: 'Optional',
    optional: true,
    forcePrivate: true
  },
  rules: []
}
