// Don't remove this file! It's a workaround for the issue of incorrect compilation of the 'moment.js' library from 'commonjs' to 'esm' by Vite during dev build.
import moment from '../node_modules/moment'
import '../node_modules/moment/dist/locale/en-gb'

export const {
  fn,
  min,
  max,
  now,
  utc,
  unix,
  months,
  isDate,
  locale,
  invalid,
  duration,
  isMoment,
  weekdays,
  parseZone,
  localeData,
  isDuration,
  monthsShort,
  weekdaysMin,
  defineLocale,
  updateLocale,
  locales,
  weekdaysShort,
  normalizeUnits,
  relativeTimeRounding,
  relativeTimeThreshold,
  calendarFormat
} = moment

locale('en-gb')

export default moment
