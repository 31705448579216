<template>
  <FormItemWrapper
    type="input"
    :has-errors="hasErrors"
    :force-show-form="forceShowForm"
    :editable="editable"
    :no-wrap-text="noWrapText"
    :focus-background-color="focusBackgroundColor"
    :query-selector-function="item => item.click()"
  >
    <template #text>
      <a-form-item
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :required="required"
      >
        <div class="form-item-text">{{ formattedDate || emptyText }}</div>
      </a-form-item>
    </template>
    <template #input="{ setEditMode }">
      <a-form-model-item
        ref="formModelItem"
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :required="required"
        :rules="customRules"
        :prop="prop"
      >
        <a-date-picker
          v-model="inputValue"
          :auto-focus="autoFocus"
          v-bind="$attrs"
          v-on="$listeners"
          @openChange="isOpen => setEditMode(isOpen)"
        />
      </a-form-model-item>
    </template>
  </FormItemWrapper>
</template>
<script>
import moment from 'moment'

import FormItemWrapper from '@/components/common/forms/FormItemWrapper'
import { DATE_TIME_FORMAT } from '@/constants/time-format'
import HInputMixin from '@/mixins/HInputMixin'

export default {
  components: { FormItemWrapper },
  extends: HInputMixin,
  props: {
    value: {
      type: [String, Object]
    }
  },
  computed: {
    inputValue: {
      set(value) {
        this.$emit('input', value)
        this.prop && this.$refs.formModelItem?.onFieldChange()
      },
      get() {
        return this.value
      }
    },
    formattedDate() {
      const format = this.$attrs.format || DATE_TIME_FORMAT
      if (this.inputValue) {
        return moment(this.inputValue).format(format)
      }
      return ''
    }
  }
}
</script>
<style lang="scss" scoped>
.form-item-wrapper {
  .ant-calendar-picker {
    width: 100%;
  }
}
</style>
