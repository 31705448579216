<template>
  <div class="scroll-x-wrapper">
    <div class="scroll-x-content" :style="`min-width: ${contentWidth}px`">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contentWidth: {
      type: [Number, String],
      default: 1100
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-x-wrapper {
  @media (min-width: 1200px) {
    .scroll-x-content {
      min-width: auto !important;
    }
  }

  @include tablet {
    overflow-x: scroll;
  }
}
</style>
